<template>
  <div class="reception-join-us">
    <div class="img-box">
      <img src="@/assets/images/case-image/page.jpg" alt=""/>
    </div>
    <cks-combined-route @active="refreshActive">
      <div class="nav">
        <div v-for="(item, index) in navTabs" :key="index" @click="navClick(index)" class="curr item" :class="{'active': index===activeIndex}">{{ item.name }}</div>
      </div>
      <div class="w content">
        <div class="cont">
          <div class="title">{{navTabs[activeIndex].name}}</div>
          <div class="eng">{{navTabs[activeIndex].english}}</div>
        </div>

        <el-empty v-if="records.length <= 0" description="暂无数据" />

        <el-row v-else :gutter="50">
          <el-col :span="8" v-for="item in records" :key="item" :sm="12" :md="8" :xs="24">
            <div class="hidden-acitve margin-bottom">
              <div class="exhibition curr hvr-grow" @click="toDetail(item)">
                <div class="title-box">
                  <img class="title-box-img" src="@/assets/images/case-image/rectangle.png" alt="">
                  <div class="cont-box">
                    <div class="title">{{ item.name }}</div>
                    <!-- <div class="date">{{ item.lastPubTime }}</div> -->
                    <div class="date">2022-12-12</div>
                  </div>
                </div>
                <img :src="item.titlePic" alt="">
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="pagination-box">
        <cks-pagination v-model="pagination" :total="total"></cks-pagination>
      </div>
    </cks-combined-route>
  </div>
</template>

<script>
import { reactive, toRefs, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import apis from '@/request/apis';
import loading from '@/assets/images/loading.png';
import { proxyImg } from '@/utils';

export default {
  setup() {
    const router = useRouter();
    const data = reactive({
      activeIndex: 0,
      loading,
      navTabs: [
        {
          name: '星级酒店',
          english: 'STAR RATED HOTEL',
        },
        {
          name: '冷链物流',
          english: 'COLD CHAIN LOGISTICS',
        },
        {
          name: '中央厨房',
          english: 'CENTRAL KITCHEN',
        },
        {
          name: '民生工程',
          english: 'PEOPLE LIVELIHOOD PROJECT',
        },
      ],
      pagination: {
        current: 1,
        size: 9,
      },
      total: 0,
      conditions: JSON.stringify([{ name: 'caseType', opr: 'eq', value: 0 }]),
      records: [],
    });

    async function load() {
      const res = await apis.receptionClassicCases.getPage({ ...data.pagination, conditions: data.conditions });
      data.records = res.records;
      data.total = res.total;
      data.records.forEach((item) => {
        proxyImg(item.titlePic, () => {
          item.titlePic = data.loading;
        }, (url) => {
          item.titlePic = url;
        });
      });
    }

    const navClick = (index) => {
      data.activeIndex = index;
      data.conditions = JSON.stringify([{ name: 'caseType', opr: 'eq', value: index }]);
      data.pagination = {
        current: 1,
        size: 9,
      };
    };

    watchEffect(() => {
      if (data.pagination) {
        load();
      }
    });

    function toDetail(row) {
      router.push({ name: 'receptionClassicCasesDetail', params: row });
    }

    return {
      ...toRefs(data),
      load,
      navClick,
      toDetail,
    };
  },
};
</script>

<style lang="scss" scoped>
.reception-join-us {
  .img-box {
    > img {
      display: block;
      width: 100%;
    }
  }
  .nav {
    background-color: #31499F;
    // height: 94px;
    display: flex;
    align-items: center;
    padding: 20px 30px;
    font-size: 35px;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 35px;
    .item {
      padding: 16px 56px;
      text-align: center;
    }
    .active.item {
      color: #31499F;
      font-weight: bold;
      background-color: #FFFFFF;
      border-radius: 10px;
      text-align: center;
    }
  }

  .content {
    padding: 100px 15px 0px;
    .cont {
      margin-bottom: 78px;
      .title {
        height: 73px;
        font-size: 76px;
        font-weight: bold;
        color: #262626;
        line-height: 73px;
        margin-bottom: 20px;
      }
      .eng {
        height: 33px;
        font-size: 37px;
        font-weight: 100;
        color: #010101;
        line-height: 33px;
      }
    }
    .margin-bottom{
      margin-bottom: 50px;
    }
    .exhibition {
      height: 376px;
      position: relative;
      display: block;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .title-box {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 86px;
        // background-image: url('~@/assets/images/case-image/rectangle.png');
        padding: 10px 30px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        .title-box-img{
          position: absolute;
          width: 100%;
          top:0;
          left:0;
        }
        .cont-box {
          height: 22px;
          display: flex;
          position: relative;
          flex: 1;
          white-space: nowrap;
          overflow:hidden;
          text-overflow: ellipsis;
          .title {
            font-size: 18px;
            line-height: 18px;
          }
          .date {
            font-size: 12px;
            line-height: 24px;
            margin-left: 14px;
          }
        }
      }
    }
  }
  .pagination-box {
    display: flex;
    justify-content: center;
    padding: 60px 0px;
  }
}
/* 当页面宽度大于1200px小于1700ppx */
@media screen and (min-width: 1200px) and (max-width: 1700px) {
  .reception-join-us {
    .nav {
      height: 74px;
      padding: 0px 30px;
      font-size: 30px;
      line-height: 30px;
      .item {
        padding: 12px 46px;
      }
      .active.item {
        border-radius: 8px;
      }
    }

    .content {
      padding: 60px 0px 0px;
      .cont {
        margin-bottom: 58px;
        .title {
          height: 68px;
          font-size: 68px;
          line-height: 68px;
          margin-bottom: 20px;
        }
        .eng {
          height: 33px;
          font-size: 37px;
          font-weight: 100;
          color: #010101;
          line-height: 33px;
        }
      }
      .exhibition {
        height: 265px;
        .title-box {
          height: 62px;
          padding: 10px 30px;
          .title-box-img{
            position: absolute;
            width: 100%;
            top:0;
            left:0;
          }
          .cont-box {
            height: 22px;
            display: flex;
            position: relative;
            flex: 1;
            .title {
              font-size: 18px;
              line-height: 18px;
            }
            .date {
              font-size: 12px;
              line-height: 24px;
            }
          }
        }
      }
    }
    .pagination-box {
      display: flex;
      justify-content: center;
      padding: 40px 0px;
    }
  }
}
/* 当页面宽度大于992px小于1200ppx */
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .reception-join-us {
    .nav {
      height: 74px;
      padding: 0px 30px;
      font-size: 30px;
      line-height: 30px;
      .item {
        padding: 12px 46px;
      }
      .active.item {
        border-radius: 8px;
      }
    }

    .content {
      padding: 50px 0px 0px;
      .cont {
        margin-bottom: 48px;
        .title {
          height: 58px;
          font-size: 58px;
          line-height: 58px;
          margin-bottom: 20px;
        }
        .eng {
          height: 33px;
          font-size: 37px;
          font-weight: 100;
          color: #010101;
          line-height: 33px;
        }
      }
      .exhibition {
        height: 216px;
        .title-box {
          height: 50px;
          padding: 10px 20px;
          .cont-box {
            height: 22px;
            display: flex;
            position: relative;
            flex: 1;
            .title {
              font-size: 16px;
              line-height: 16px;
            }
            .date {
              font-size: 12px;
              line-height: 19px;
            }
          }
        }
      }
    }
    .pagination-box {
      display: flex;
      justify-content: center;
      padding: 30px 0px;
    }
  }
}
/* 当页面宽度大于768px小于992ppx */
@media screen and (min-width: 768px) and (max-width: 992px) {
  ::v-deep.el-row {
    margin: 0 !important;
  }
  ::v-deep.el-col {
    padding: 0 !important;
  }
  .reception-join-us {
    .img-box {
      margin-top: 60px;
    }
    .nav {
      height: 120px;
      padding: 0px 20px;
      font-size: 24px;
      line-height: 24px;
      display: flex;
      flex-flow:row wrap;
      .item {
        width: 50%;
        padding: 12px 16px;
      }
      .active.item {
        border-radius: 8px;
      }
    }

    .content {
      padding: 30px 15px 0px;
      .cont {
        margin-bottom: 28px;
        .title {
          text-align: center;
          height: 33px;
          height: 38px;
          font-size: 38px;
          line-height: 38px;
          margin-bottom: 14px;
        }
        .eng {
          text-align: center;
          height: 30px;
          height: 30px;
          font-size: 37px;
          font-weight: 100;
          color: #010101;
          line-height: 30px;
        }
      }
      .exhibition {
        height: 260px;
        .title-box {
          height: 60px;
          padding: 10px 20px;
          .cont-box {
            height: 20px;
            display: flex;
            position: relative;
            flex: 1;
            .title {
              font-size: 16px;
              line-height: 16px;
            }
            .date {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }
    }
    .pagination-box {
      display: flex;
      justify-content: center;
      padding: 30px 0px;
    }
  }
}
/* 当页面宽度小于768px */
@media screen and (max-width: 768px) {
  ::v-deep.el-row {
    margin: 0 !important;
  }
  ::v-deep.el-col {
    padding: 0 !important;
  }
  .reception-join-us {
    .img-box {
      margin-top: 60px;
    }
    .nav {
      height: 80px;
      padding: 0px 20px;
      font-size: 20px;
      line-height: 20px;
      display: flex;
      flex-flow:row wrap;
      .item {
        width: 50%;
        padding: 6px 16px;
      }
      .active.item {
        border-radius: 4px;
      }
    }

    .content {
      padding: 30px 15px 0px;
      .cont {
        margin-bottom: 28px;
        .title {
          text-align: center;
          height: 26px;
          font-size: 26px;
          line-height: 26px;
          margin-bottom: 14px;
        }
        .eng {
          text-align: center;
          height: 26px;
          font-size: 26px;
          font-weight: 100;
          color: #010101;
          line-height: 26px;
        }
      }
      .exhibition {
        height: calc((100vw - 50px) * 380 / 526);
        .title-box {
          height: calc((100vw - 50px) * 86 / 526);
          padding: 10px 20px;
          .cont-box {
            height: 20px;
            display: flex;
            position: relative;
            flex: 1;
            .title {
              font-size: 16px;
              line-height: 16px;
            }
            .date {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }
    }
    .pagination-box {
      display: flex;
      justify-content: center;
      padding: 20px 0px;
    }
  }
}
</style>
